* {
  --color-dark: #22222a;
  --color-blue: #6699ff;
  box-sizing: content-box;
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 12pt;
  color: var(--color-dark);
}

a {
  text-decoration: none;
}

p {}

/* .container {
  background-color: #f0f;
} */