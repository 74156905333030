.shikigamiproject {
    min-height: 100vh;
    background-color: chartreuse;
}

.navbar-shikigami {
    border-bottom: none;
    background-color: #fffa;
}

.banner {
    width: 100%;
}