/* -------------------------------------------------- */
/* GLOBAIS 											  */
/* -------------------------------------------------- */
* {
	box-sizing: border-box;
}

/* -------------------------------------------------- */
/* MAIN 										      */
/* -------------------------------------------------- */
.stnixe {
	min-height: 100vh;
	display: flex;
	flex-flow: column;
	font-size: 14pt;
	background-image: url('../../img/bg-page.png');
	color: #fff;
	font-family: sans-serif;
}

/* -------------------------------------------------- */
/* NAVBAR											  */
/* -------------------------------------------------- */
.navbar {
	position: sticky;
	top: 0;
	padding: 16px;
	display: flex;
	border-bottom: 1px solid #3f3f3f;
	backdrop-filter: blur(4px);
	background-color: #22222fdd;
	font-weight: bold;
	z-index: 100;
}

.nav-item {}

.nav-item:nth-of-type(2) {
	flex-grow: 1;
	text-align: right;
}

.nav-item:nth-of-type(1)>.nav-link > img {
	width: 128px;
}

.nav-item:nth-of-type(2)>.nav-link {
	margin: 10px;
}

.nav-link {
	transition: 0.2s;
	text-transform: uppercase;
	color: #fff;
}

.nav-link:hover {
	color: #66f;
}

/* -------------------------------------------------- */
/* FOOTER											  */
/* -------------------------------------------------- */
.ftr {
	padding: 24px;
	display: flex;
	flex-flow: column;
	align-items: center;
	font-size: 10pt;
}

.ftr>nav {
	padding: 16px;
	display: flex;
}

.ftr>nav>a {
	margin: 18px;
	font-size: 14pt;
	align-items: center;
	color: #fff;
	transition: 0.2s;
}

.ftr>nav>a:hover {
	color: #66f;
}

/* -------------------------------------------------- */
/* CONTAINERS										  */
/* -------------------------------------------------- */
/* Block */
.block {
	min-height: 256px;
	display: flex;
	flex-flow: column;
}

/*.block > span, div {
	flex: 0 1 auto;
}*/

.block-title,
.block-content {
	padding: 16px;
}

.block-title {
	margin: 0 5%;
	padding: 40px 0 4px 0;
	justify-content: flex-start;
	font-size: 24pt;
	border-bottom: 1px solid #3f3f3f;
}

.block-content {
	flex-grow: 1;
}

.container {
	max-width: 1366px;
	margin: auto;
}

/* -------------------------------------------------- */
/* Container Vazio */
.align-center {
	padding: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
}
/* -------------------------------------------------- */
/* Slider */
.slider {
	height: 100vh;
	display: flex;
	flex-flow: column;
	background-image: url('../../img/bg-2.png');
	background-repeat: no-repeat;
	background-size: cover;
}
.slider > img {
	width: 304px;
	margin: auto;
	align-items: center;
	justify-content: center;
}
@media (max-width: 1024px) {
	.slider > img {
		width: 256px;
	}
}
/* @media (max-width: 480px) {
	.slider > img {
		width: 80%;
	}
} */
/* -------------------------------------------------- */
/* Card */
.card {
	width: 224px;
	height: 320px;
	margin: 10px;
	display: flex;
	flex-flow: column;
}

.card-overlay {
	display: flex;
	flex-flow: column;
	flex-grow: 1;
	opacity: 0;
	background-color: #22222faa;
	box-shadow: inset 0px 0px 1px 1px #69f;
	transition: 0.4s;
	z-index: 10;
}

.card-overlay:hover {
	opacity: 1;
}

.card-btn {
	margin: auto 16px 16px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #69f;
	background-color: #22222fdd;
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
}

.card-align {
	/* display: grid;
	grid-template-columns: repeat(auto-fill, 224px);
	grid-template-rows: 1fr;
	grid-gap: 10px; */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.card-reitekina { background-image: url(../../img/card-1.png); }
.card-shikigami { background-image: url(../../img/card-3.png); }
